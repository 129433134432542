/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body and html reset */
html,
body {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  margin: 0;
  padding: 0;
}

/* Ensure the container fills the viewport */
.fp-card-container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  /*align-items: center;*/
  padding: 0;
  margin: 0;
}

/* Card styling */
.fp-card {
  background: rgba(232, 233, 234, 0.8); /* Transparent white background */
  border-radius: 20px; /* Increased border-radius */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow for more depth */
 /* width: 1100px; /* Slightly wider card */ 
  padding: 45px 55px; /* Adjusted padding for more spacing */
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  backdrop-filter: blur(4px); /* Stronger blur effect */
  margin-right: 82px;
  margin-bottom: 80px;
}

.fp-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex: 1 1;
  width: 100%;
}

.logo-container{
  display: flex;
  justify-content: flex-end;
  width:100%;
}

.inputs-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*margin-right: 63px;*/
  margin: 12px 63px 12px 0;
}

.fp-title-container{
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.fp-inputs-container{
  display: flex; 
  justify-content: center; 
  gap:40px;
}

.fp-input-left{
  display: flex; 
  justify-content: flex-start; 
  flex-direction: column;
  width: 100%;
}

.fp-input-right{
  display: flex; 
  flex-direction: column;
  justify-content: flex-start; 
  width: 100%;
}

.fp-button-container{
  display: flex; 
  justify-content: flex-start; 
  flex-direction: column;
}

.login-button-container{
  display: flex; 
  justify-content: flex-end; 
}


/*logo styling*/
.login-logo{
  width: 60px;
  height: 60px;
  margin-bottom: 11px;
}

/* Title styling */
.fp-title {
  font-family: 'Open Sans';
  font-size: 1.5625em; /* Larger font size */
  color: #167DB0; /* Adjusted color to match the design */
  
  margin-bottom: 10px; /* Added margin for spacing */
}

/* Bold text for title */
.fp-bold-text {
  font-weight: bold;
}

/* Subtitle styling */
.fp-subtitle {
  font-size: 1.2em; /* Increased font size */
  color: #333;
  margin-top: 0;
  margin-bottom: 20px; /* Added margin-bottom for spacing */
}

/* Input container styling */
.fp-input-container {
  max-width: 400px;
  margin-bottom: 10px;
}

.fp-or-label {
  font-family: 'Open Sans';
  font-size: 1.125em;
  color: #343544;
  margin: 30px 0 40px 0;
  display: block;
  font-weight: bold;
}

.fp-otp-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 400px;
  margin-bottom: 5px;
  min-height: 95px;
}

/* Input label styling */
.fp-input-label {
  font-family: 'Open Sans';
  font-size: 1.125em;
  color: #343544;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

/* Input field styling */
.fp-input {
  width: 100%;
  padding: 10px; /* Increased padding */
  border: 1px solid #ffffff;
  border-radius: 7px;
  font-size: 1em;
  margin-bottom: 12px;
  /* background: rgba(255, 255, 255, 0.75);  */
  /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

.fp-input-otp{
  max-width: 40px;
  padding: 10px; /* Increased padding */
  border: 1px solid #ffffff;
  border-radius: 7px;
  font-size: 1em;
  
  
}

.fp-input-msg{
  font-family: 'Open Sans';
  font-size: 1em;
}

.input-spacer{
  display:"flex";
  flex-direction: column;
  gap:"10px";
}

.required{
  color: red;
}



.verify-button{
    width: 30%;
    padding: 0;
    margin-bottom: 12px;
    background-color: #167DB0;
    color: #fff;
    border: none;
    border-radius: 130px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.details-button{
    width: 20%;
    padding: 12px;
    margin-bottom: 12px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 100%;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button styling */
.fp-button {
  width: 100%;
  padding: 12px;
  margin-top: 5px; /* Increased margin-top for spacing */
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fp-button:hover {
  background-color: #555; /* Lighter color on hover */
}

/* Forgot password link styling */
.fp-forgot-password {
  font-size: 1em; /* Larger font size */
  color: #e15d5d;
  margin-top: 20px; /* Increased margin for spacing */
  text-decoration: none;
}

.fp-forgot-password:hover {
  text-decoration: underline;
}

.login-already{
  font-family: 'Open Sans';
  font-size: 1.125em;
  color: #343544;
  margin-top: 20px; /* Increased margin for spacing */
  text-decoration: none;
}

.login-back{
  font-family: 'Open Sans';
  font-size: 1.5625em;
  font-weight: 600;
  color: #167DB0;
  text-decoration:none;
}

.login-back:hover {
  text-decoration: underline;
}

