/* Import Open Sans from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap");

/* Card container styling */
.card-container {
  width: 100%;
  max-width: 100%; /* Set to 100% to make the container full-width */
  margin: 0 auto;
  border: 1px solid black; /* 1px black border around the card */
  border-radius: 8px;
  padding: 10px; /* Adjusted padding for the 10px spacing around the container */
  background-color: white;
  box-sizing: border-box; /* Ensures padding is included in the width */
}

.main-container{
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

/* Table container */
.table-container {
  width: auto !important;
  overflow-x: auto;
}

table {
  width: 100% !important; /* Full-width table */
  border-collapse: collapse;
  font-family: "Open Sans", sans-serif;
  font-weight: 600; /* Semibold for the rest of the table */
  font-size: 14px !important; /* Adjust font size for the table */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  box-sizing: border-box; /* Ensure table takes up full width */
  border-spacing: 10px; /* 10px spacing between cells */
}

/* Table header */
thead th {
  padding: 20px 5px 20px 5px !important;
  text-align: center !important;
  border-bottom: 2px solid #ffffff !important;
  font-size: 14px !important;
  /* font-family: sans-serif; */
  color: #000;
  font-weight: bold !important;
  background-color: #ffffff !important;
  width: 200px !important;
}

thead th:first-child,
thead th:last-child {
  width: 50px; /* Adjust width for checkbox and delete columns */
}

thead img {
  margin-left: 5px;
  width: 10px;
  height: 12px;
}

tbody td {
  padding:12px !important;
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-size: 14px !important;
  color: #262626;

}

tbody td.checkbox-column {
  text-align: center;
  width: 50px;
}


.table-column-checkbox .form-check-input:checked {
  background-image:url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27><path fill=%27none%27 stroke=%27black%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/></svg>');
  border-color: black;
  background-color: white;
}

.table-column-checkbox .form-check-input { 
  width: 18px;
  height: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 2px #00000029;
  border: 1px solid #262626;
  border-radius: 3px !important;
  opacity: 1;
}

tbody td.delete-column {
  text-align: center;
  width: 50px;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

tbody tr:nth-child(odd) {
  background-color: #f2f4f5;
}

.custom-dropdown button{
  background: white;
  color: black;
  border: 0px;
}
.custom-dropdown button:hover{
  background: none;
  color: black;
}

.custom-dropdown button:focus{
  background: none;
  color: black;
}
.custom-header-setting{
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 19px;
  flex-direction: row;
  position: relative;
  top: 30px;
  right: -3px;
}
.react-bs-table-pagination .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
.boot-custom-table th{
  padding: 10px !important;
  text-align: center !important;
  border: none !important;
}

.custom-table-sort div{
  display: flex !important;
  flex-direction: column !important;
  padding: 2px !important;
  justify-content: center !important;
  align-items: center !important;
  justify-items: center !important;

}


.boot-custom-table tr th td {
  border: none !important;
}

.boot-custom-table td{
  background: none !important;
}

.overlay-body-pagination{
  width: fit-content;

}
.react-bootstrap-table  {
  width: 100%;
  overflow: auto;
  }

.btn-page-dropdown-button:focus{
  border: none;
  background: none;
}

.row-select{
  background-color: #dce3e6 !important;
}

 .boot-custom-table{
  table-layout: auto !important;
  border: 1px solid lightgrey;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 }
.table-pagination .page-item .page-link {
  border: 0px !important;
  color: black;
  font-size: medium;
}

.table-pagination .page-item .page-link:hover {
  box-shadow: 0 0 0 0px lightblue !important;
  background-color: none !important;
  border-radius: 4px !important;
}


.table-pagination .page-item .page-link:focus {
   box-shadow: none !important;
   background-color: none !important;
   border-radius: 4px !important;
}



.table-pagination  .active .page-link{
  background-color: #333;
  color: white;
  border-radius: 4px;
}

.table-pagination{
  background: none !important;
}

.table-pagination:hover{
  background:none !important;
}
.table-pagination:focus{
  background: none !important;
}

.table-pagination .page-item:focus{
  color: transparent !important;
  background-color: none !important;
}
.table-pagination .page-item:hover{
  color: transparent !important;
  background-color: none !important;
}

.table-pagination .page-link:hover{
  background: none !important;
}

.table-pagination .page-link:focus{
  background: none !important;
}

.table-pagination  button:hover{
  background: none !important;
}



.table-pagination  .disable .page-link{
  background-color: transparent !important;

}
.table-pagination .disable {
  background-color: transparent !important;
  color: #000;
}

.page-input{
  border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 2px;
    width: 60px;
    text-align: center;
}

/* Styled Checkbox */
.styled-checkbox {
  width: 18px; /* Increased size to match the image */
  height: 18px;
  border-radius: 4px; /* Rounded corners */
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 900;
  position: relative;
}

/* Alert column styles */
.alert {
  font-weight: 700; /* Bold for alerts */
}

.normal {
  color: blue;
}

.major {
  color: orange;
}

.critical {
  color: red;
}

/* Delete icon */
.delete-icon {
  cursor: pointer;
  width: 17px;
  height: 20px;
}

/* Pagination container styling */
.pagination-container {
  display: flex;
  justify-content: flex-end; /* Align pagination to the right */
  margin-top: 20px;
}

.pagination {
  display: flex;
}

.pagination button {
  border: none;
  background-color: transparent; /* Transparent background */
  padding: 8px 12px;
  margin: 0 2px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #333;
  font-weight: 600; /* Semibold */
}

/* Styling for active page */
.pagination button.active {
  background-color: #333; /* Dark background */
  color: white;
  border-radius: 4px; /* Rounded corners */
}

/* Styling for previous and next buttons */
.pagination button.prev-next {
  font-size: 16px;
  font-weight: 600;
}

.pagination button:hover {
  background-color: #ddd;
}

/* Styled Checkbox */
.styled-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 24px; /* Adjust size */
  height: 24px;
  border-radius: 4px;
  border: 1px solid #000; /* Dark border */
  cursor: pointer;
  display: inline-block;
  position: relative;
  background-color: #fff; /* Background white when not checked */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Checkmark when checkbox is checked */
.styled-checkbox:checked {
  background-color: #d3d7df; /* Slightly grey background when checked */
  border: 2px solid #000; /* Darker border */
}

/* SVG-based tick mark for better control */
.styled-checkbox:checked::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M9 11.93l-3.47-3.47L4.1 10.88l5.9 5.9L20.48 6.29l-1.41-1.41L9 11.93z"/></svg>')
    no-repeat center;
  background-size: 22px;
}

/* Main container holding the search bar and card */

/* Search bar container */
.search-bar-container {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: flex-end; /* Right-align the search bar */
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px 0;
  margin-top: 20px;
  margin-right: 0px;
}

/* Filter and settings icons */
.filter-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.setting-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

/* Divider styling */
.divider {
  width: 1px;
  height: 10px; /* Adjust height to match the icon size */
  background-color: #ccc; /* Light grey color for the divider */
  margin: 0 10px; /* Add spacing between the icons */
}

/* Search input container */
.search-input-container {
  position: relative;
  width: 40%;
}

/* Search input styling */
.search-input {
  width: 100%;
  padding: 8px 35px;
  border-radius: 10px;
  border: 0px solid #ccc;
  background-color: #dde5ec;
  box-sizing: border-box;
  font-size: 16px;
  height: 50px;
}

/* Search icon inside input */
.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
}

/* Card container styling */
.card-container {
  border: 1px solid black;
  border-radius: 8px;
  padding: 20px;
}


/* Pagination */
.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Dropdown container */
.dropdowns-container {
  position: relative;
  display: inline-block;
}


/* Dropdown */
.dropdowns {
  position: absolute;
  top: 35px; /* Adjusted to make the dropdown appear below the icon */
  right: 0;
  z-index: 1;
  width: 250px; /* Exact width */
  background-color: white;
  border: 1px solid #ddd; /* Light border similar to the image */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 10px; /* Internal padding to match the spacing */
  font-family: "Arial", sans-serif; /* Similar font to match the design */
  font-size: 16px;
}

/* Dropdown label styling */
.dropdowns label {
  display: flex; /* Use flex to align the checkbox and text properly */
  align-items: center; /* Align the text vertically in the center */
  padding: 8px 0; /* Spacing between options */
  font-weight: 400;
  color: #333; /* Darker color for the text */
  cursor: pointer;
  user-select: none; /* Disable text selection */
}

/* Checkbox styling */
.dropdowns input[type="checkbox"] {
  margin-right: 10px; /* Spacing between checkbox and label */
  accent-color: black; /* Black checkbox color when selected */
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Checkbox appearance when checked */
.dropdowns input[type="checkbox"]:checked {
  background-color: #000; /* Black background for checked checkboxes */
  border-color: #000; /* Black border */
}

/* Additional styling for hover effect */
.dropdowns label:hover {
  background-color: #f5f5f5; /* Light background on hover */
  border-radius: 5px;
}

/* Notch effect pointing to the icon */
.dropdowns::before {  
  content: "";
  position: absolute;
  top: -12px; /* Position the notch just above the dropdown */
  right: 4px; /* Center the notch under the settings icon */
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white; /* Matches the background of the dropdown */
  z-index: 2; /* Ensure it's on top of the dropdown */
}

/* Notch shadow effect for depth */
.dropdowns::after {
  content: "";
  position: absolute;
  top: -12px; /* Slightly above the main notch for the shadow */
  right: 4px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 1; /* Place behind the main notch */
}

.overlay-body{
 
  background-color: white;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 10px; /* Internal padding to match the spacing */
  font-family: "Arial", sans-serif; /* Similar font to match the design */
  font-size: 16px;
}
.overlay-body  label {
  display: flex; /* Use flex to align the checkbox and text properly */
  align-items: center; /* Align the text vertically in the center */
  font-weight: 400;
  color: #333; /* Darker color for the text */
  cursor: pointer;
  user-select: none; /* Disable text selection */
}

/* Checkbox styling */
.overlay-body input[type="checkbox"] {
  margin-right: 10px; /* Spacing between checkbox and label */
  accent-color: black; /* Black checkbox color when selected */
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Checkbox appearance when checked */
.overlay-body input[type="checkbox"]:checked {
  background-color: #000; /* Black background for checked checkboxes */
  border-color: #000; /* Black border */
}

/* Additional styling for hover effect */
.overlay-body label:hover {
  background-color: #f5f5f5; /* Light background on hover */
  border-radius: 5px;
}

.overlay-body-menu{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.overlay-body-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.overlay-body-menu ul li {
  padding: 1px 0px;
  cursor: pointer;
  font-size: 14px;
}

.overlay-body-menu ul li:hover {
  background-color: #f1f1f1;
}

/* Nested dropdown for Location */
.overlay-body-menu ul li ul {
  margin-top: 8px;
  padding-left: 10px;
}

.overlay-body-menu ul li ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.overlay-body-menu ul li:hover ul li:hover {
  background-color: #e9e9e9;
}

.card-design{
  border: 1px solid lightgrey;
  box-shadow: #d3d7df;
}



/* Filter Menu Styling */
.filter-menu {
  position: absolute;
  top: 40px; /* Distance from filter icon */
  left: 20px; /* Adjust this to position below the icon */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 180px; /* Adjust width as needed */
  z-index: 1000;
}

.filter-menu:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 30px; /* Adjust to match with the filter icon */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white; /* Matches the menu background */
  z-index: 1001;
}

.filter-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

.filter-menu ul li:hover {
  background-color: #f1f1f1;
}

/* Nested dropdown for Location */
.filter-menu ul li ul {
  margin-top: 8px;
  padding-left: 10px;
}

.filter-menu ul li ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.filter-menu ul li:hover ul li:hover {
  background-color: #e9e9e9;
}

.filter-dropdown {
  position: relative;
}

.filter-menu {
  position: absolute;
  top: 40px; /* adjust based on your design */
  left: -15px;
  width: 180px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.filter-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 15px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.filter-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filter-menu ul li {
  padding: 10px;
  cursor: pointer;
}

.filter-menu ul li:hover {
  background-color: #f0f0f0;
}

.filter-menu ul ul {
  padding-left: 20px;
}
