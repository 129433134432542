.alerts-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(32% - 16px);
  min-width: 300px;
  width: 100%;
}

@media (max-width: 1024px) {
  .alerts-card {
    flex: 1 1 calc(48% - 16px);
  }
}

@media (max-width: 768px) {
  .alerts-card {
    flex: 1 1 100%;
    margin-bottom: 16px;
  }
}

.alerts-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -10px;
}

.alerts-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .alerts-title {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .alerts-title {
    font-size: 12px;
  }
}

.alerts-divider {
  width: calc(100% + 40px);
  height: 1px;
  background-color: #ddd;
  position: relative;
  left: -20px;
  margin-bottom: 20px;
}

.alerts-chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alerts-details {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.alerts-item {
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alerts-count {
  font-weight: bold;
  font-size: 18px;
  margin-right: 5px;
}

.alerts-text {
  font-weight: normal;
  color: #818F96;
  font-size: 17px;
}
