* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.overview {
  padding: 0 30px;
  margin-top: 30px;
}



.overview-cards {
  display: flex;
  justify-content: space-between;
  gap: 26px;
}

.production-overview-card,
.performance-alerts-card {
  flex: 1;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}
