/* SettingsForm.css */
.settings-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.settings-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.settings-container h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }


.settings-form-content-box {
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #C2CAD2;
    border-radius: 5px;
    background-color: #F2F5F7;
}

.settings-form-content-blocks {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* flex: 1 30px; */
    gap: 75px;

}

.settings-form-section {
  margin-bottom: 25px;
}

.settings-form-section h3 {
  display: flex;
  align-items: normal;
  gap: 8px;
  font-size: 16px;
  margin-bottom: 10px;
}

.settings-form-section label {
  font-size: 14px;
  font-weight: bold;
  width: 10%;
}

.settings-form-section select,
.settings-form-section input[type="text"],
.settings-form-section input[type="password"] {
  width: 250px;
  padding: 5px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.settings-input-bottom-info {
    display: flex;
    flex-direction: column;
}

.settings-radio-group {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.settings-radio-group label {
  font-weight: normal;
}

.settings-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.settings-checkbox-group label {
  font-weight: normal;
}

.settings-checkboxes {
  width: 16px;
  height: 16px;
}

.settings-modal-content {
  background-color: #00ff73;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  margin: 0 auto;
}

.settings-modal-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.settings-modal-body {
  font-size: 16px;
  margin-bottom: 20px;
}

.settings-modal-close-button {
  padding: 8px 15px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.settings-modal-close-button:hover {
  background-color: hsl(0, 2%, 10%);
}

.settings-location-type-table {
    width: 100%;
    border-collapse: collapse;
    /* table-layout: fixed; */
    margin: 0;
  }
  
  .settings-location-type-table th, .location-type-table td {
    width: 20%;
    padding: 20px 12px;
    /* border: 1px solid #ddd; */
    text-align: left;
  }
  
  .settings-location-type-table th {
    background-color: #F2F5F7;
    font-weight: bold;
  }
  
  .settings-location-type-table tr:nth-child(even) {
    background-color: #F2F5F7;
  }
  
  thead th:first-child, thead th:last-child {
   width: 55%;
}

  .settings-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 5px;
  }
  
  .settings-button-reset {
    background-color: #6c757d;
    color: #fff;
  }
  
  .settings-button-reset:hover {
    background-color: #5a6268;
  }
  
  .settings-error {
    color: red;
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
  }

  .settings-password-container {
  display: flex;
  align-items: center;
  position: relative;
}

.settings-password-container input {
  width: 100%;
  padding-right: 30px; /* Space for the eye icon */
}

.settings-toggle-visibility {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.settings-required {
    color: red;
}

.settings-file-upload-input {
    display: inline-block;
    margin-top: 5px;
    cursor: pointer;
    background-color: white;
    /* width: 20%; */
    border: 1px solid #eae9e9;
    border-radius: 8px;
  }
  
  .settings-file-upload-input::file-selector-button {
    background-color: #ccc;
    color: #333;
    border: none;
    box-shadow: 2px 2px 2px #8D8D8D73;
    border-radius: 8px;    
    padding: 10px 25px;
    cursor: pointer;
  }
  
  .settings-file-upload-input::file-selector-button:hover {
    background-color: #bbb;
  }
  
  .settings-file-upload-input::content {
    background-color: #fff;
  }
  .settings-file-info {
    display: block;
    font-size: 12px;
    color: #666;
    margin-top: 5px;
    margin-left: 9.5%;
  }
  
  .settings-yield-input-section {
    display: flex;
    gap: 10px;
  }

  /******/
  .settings-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .settings-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .settings-form-content-box {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #C2CAD2;
    border-radius: 5px;
    background-color: #F2F5F7;
    position: relative;
  }

  .settings-button-edit {
    width: 16px;;
  }
  
  .settings-button-save {
    padding: 5px 38px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .settings-button-save:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  

/* Modal overlay to center the modal */
.settings-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content container */
.settings-modal-content {
  background-color: #fff; /* set background to white */
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  position: relative;
}

/* Modal header (make text bold and green) */
.settings-modal-header {
  font-size: 20px;
  font-weight: bold;
  color: #0a9d0a; 
  margin-bottom: 10px;
}

/* Modal body */
.settings-modal-body {
  font-size: 16px;
  margin-bottom: 20px;
}

.settings-modal-close-button {
  padding: 8px 15px;
  background-color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.settings-modal-close-button:hover {
  background-color: #333;
}

/* Style for the uploaded file link */
.uploaded-file-info {
  margin-top: 10px;
  font-size: 0.9rem;
}

.uploaded-file-info a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.uploaded-file-info a:hover {
  text-decoration: underline;
}
