.overviewsection2 {
  font-family: Arial, sans-serif;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
}

.overviewsection2-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.overviewsection2-container > div {
  flex: 1;
  min-width: 300px;
  margin: 8px;
}

.overviewsection2-container > * {
  flex: 1 1 calc(48% - 16px); /* Adjust the width to fit two cards side by side */
  margin: 8px;
  box-sizing: border-box;
  height: 350px; /* Ensure all cards have the same height */
}
