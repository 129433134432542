* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.solor-navbar{
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #000000 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.solor-navbar .navbar-toggler {
  background-color: lightgrey !important;
  }


  @media (min-width:250px) and (max-width: 990px) {
   .solor-navbar .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        background: black;
        padding-left: 30px;
        margin-left: -11px;
        margin-right: -11px;
        margin-top: 10px;
        padding-top: 20px;
        z-index: 500;
    }
    .solor-navbar .navbar-nav{
      margin-top: 20px;
      margin-bottom: 20px;
    }
}
 


  .solor-navbar .navbar-links  {
    font-family: "Open Sans", Tahoma, Geneva, Verdana, sans-serif !important;
    text-decoration: none !important;
    color: #fff !important;
    font-weight: bold !important;
    position: relative !important;
    padding: 0;
    font-size: 1em !important;
  
  }

  .solor-navbar .navbar-link-active::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px !important;
    border-radius: 8px !important;
    background-color: #3BBCAF !important; /* Color for the active link underline */
    color: #3BBCAF !important;
    position: absolute !important;
    bottom: -39px !important;
    left: 0 !important;
  }

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 30px;
  margin-right: 10px;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 50px;
}

.navbar-links li {
  padding: 10px 0;
}

.navbar-links  {
  font-family: "Open Sans", Tahoma, Geneva, Verdana, sans-serif ;
  text-decoration: none ;
  color: #fff;
  font-weight: bold ;
  position: relative ;
  padding: 0;
  font-size: 1em ;

}

.navbar-link-active {
  color: #3BBCAF; /* Color for the active link */
}

/* .navbar-link-active::after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background-color: #007bff;
  position: absolute;
  bottom: -10px;
  left: 0;
} */

.navbar-link-active::after {
  content: "";
  display: block;
  width: 100%;
  height: 4px ;
  border-radius: 8px;
  background-color: #3BBCAF; /* Color for the active link underline */
  color: #3BBCAF;
  position: absolute ;
  bottom: -39px ;
  left: 0 ;
}


.navbar-links a.active{
  color: #3BBCAF; /* Color for the active link */
}

.navbar-icons {
  display: flex;
  align-items: center; /* Center icons vertically */
  gap: 30px;
}

.navbar-bell-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.navbar-profile-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.bottom-bar{
  height: auto;
  padding: 10px 20px;
  background: #CCCCCC;
}
.top-search-container{
  display: flex;
  align-items: center;
  background-color: #ADADAD;
  padding: 5px 15px;
  border-radius: 50px;
  margin-right: 20px;
}
.top-search{
  width: auto;
  border: none;
  background: #ADADAD;
  color: #F4F6F7;
  outline: none;
  padding: 9px;
}