/* Ensure the container fills the viewport */
.forgotp-card-container {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    /*align-items: center;*/
    padding: 0;
    margin: 0;
  }

.forgotp-card {
background: rgba(232, 233, 234, 0.8); /* Transparent white background */
border-radius: 20px; /* Increased border-radius */
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow for more depth */
width: 493px; /* Slightly wider card */ 
padding: 20px 32px; /* Adjusted padding for more spacing */
border: 2px solid #fff;
display: flex;
flex-direction: column;
align-items: center;
align-self: flex-end;
backdrop-filter: blur(4px); /* Stronger blur effect */
margin-right: 82px;
margin-bottom: 110px;
}

.forgotp-container {
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
.forgotp-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }

/* Title styling */
.forgotp-title {
    font-size: 1.5625em; /* Larger font size */
    color: #167DB0; /* Adjusted color to match the design */
    margin-bottom: 10px; /* Added margin for spacing */
  }

.forgotp-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 400px;
    min-height: 242px;
    margin-top: 20px;
}
.forgotp-input-group {
    margin-bottom: 15px;
  }
  
.forgotp-input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

.forgotp-otp-boxes {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }
  
.forgotp-otp-input {
    width: 50px;
    padding: 10px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 7px;
  }
  
  /*.input-field {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
  }*/
.forgotp-inputs-container{
    display: flex; 
    justify-content: center; 
    gap:40px;
  }

.forgotp-input-container {
    width: 100%;
    margin-bottom: 10px;
  }

  /* Input label styling */
.forgotp-input-label {
    font-family: 'Open Sans';
    font-size: 1.125em;
    color: #343544;
    margin-bottom: 10px;
    display: block;
    font-weight: bold;
  }

  /* Input field styling */
.forgotp-input {
    width: 100%;
    padding: 10px; /* Increased padding */
    border: 1px solid #ffffff;
    border-radius: 7px;
    font-size: 1em;
    margin-bottom: 12px;
    /* background: rgba(255, 255, 255, 0.75);  */
    /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1); */
  }
  


/* Button styling */
.forgotp-submit-button {
    width: 100%;
    padding: 12px;
    /*margin-top: 20px; /* Increased margin-top for spacing */
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .forgotp-submit-button:hover {
    background-color: #555; /* Lighter color on hover */
  }
  
  .forgotp-timer {
    margin-left: 10px;
    font-size: 14px;
  }
  
  .forgotp-resend-button {
    background-color: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
  }
  
  .forgotp-resend-button:hover {
    text-decoration: underline;
  }
  
  .forgotp-error-message {
    color: red;
    font-size: 14px;
  }
  
  .forgotp-otp-form,
  .forgotp-password-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 400px;
    min-height: 242px;
    margin-top: 20px;
    margin-top: 20px;
  }
  
  .forgotp-login-back{
    margin-top: 10px;
    font-family: 'Open Sans';
    font-size: 0.8em;
    font-weight: 300;
    color: #167DB0;
    text-decoration:none;
  }
  
  .forgotp-login-back:hover {
    text-decoration: underline;
  }