/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body and html reset */
html,
body {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  margin: 0;
  padding: 0;
}

/* Ensure the container fills the viewport */
.login-card-container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  /*align-items: center;*/
  padding: 0;
  margin: 0;
}

/* Card styling */
.login-card {
  background: rgba(232, 233, 234, 0.8); /* Transparent white background */
  border-radius: 20px; /* Increased border-radius */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Increased shadow for more depth */
  width: 493px; /* Slightly wider card */ 
  padding: 20px 32px; /* Adjusted padding for more spacing */
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  backdrop-filter: blur(4px); /* Stronger blur effect */
  margin-right: 82px;
  margin-bottom: 110px;
}

/*logo styling*/
.login-logo{
  width: 60px;
  height: 60px;
  margin-bottom: 11px;
}

/* Title styling */
.login-title {
  font-size: 1.5625em; /* Larger font size */
  color: #167DB0; /* Adjusted color to match the design */
  margin-bottom: 10px; /* Added margin for spacing */
}

/* Bold text for title */
.login-bold-text {
  font-weight: bold;
}

/* Subtitle styling */
.login-subtitle {
  font-size: 1.2em; /* Increased font size */
  color: #333;
  margin-top: 0;
  margin-bottom: 20px; /* Added margin-bottom for spacing */
}

/* Input container styling */
.login-input-container {
  width: 100%;
  margin-bottom: 10px;
}

/* Input label styling */
.login-input-label {
  font-size: 1.125em;
  color: #343544;
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}

/* Input field styling */
.login-input {
  width: 100%;
  padding: 10px; /* Increased padding */
  border: 1px solid #77799F;
  border-radius: 10px;
  font-size: 1em;
  /* background: rgba(255, 255, 255, 0.75);  */
  /* box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

/* Button styling */
.login-button {
  width: 100%;
  padding: 12px;
  margin-top: 20px; /* Increased margin-top for spacing */
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #555; /* Lighter color on hover */
}

.login-signup-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  
}

/* Forgot password link styling */
.login-forgot-password-link {
  font-family:'Open Sans';
  font-weight: 500;
  font-size: 1em; /* Larger font size */
  color: #167DB0;
  margin-top: 20px; /* Increased margin for spacing */
  text-decoration: none;
}

.login-forgot-password-link:hover {
  text-decoration: underline;
}

.login-signup-link{
  font-family:'Open Sans';
  font-weight: 500;
  font-size: 1em; /* Larger font size */
  color: #167DB0;
  margin-top: 20px; /* Increased margin for spacing */
  text-decoration: none;
}

.login-signup-link:hover {
  text-decoration: underline;
}