.plant-button{background-color: green !important;}

.header-section {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between; /* Added this line */
  }
  
  .header-section1 {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .header-section2 {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 15px;
  }
  
  .palnts {
    font-size: 24px;
    color: #333;
    padding: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .plant-count {
    font-size: 12px;
    color: #999;
    margin-left: 10px;
    margin-top: 9px;
  }
  
  .header-title {
    font-size: 24px;
    color: #333;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    align-items: center;
  }
  
  /* Add New Plant Button */
  .add-plant-button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .add-plant-button:hover {
    background-color: #444;
  }
  
  .divider {
    width: 1px;
    height: 20px;
    background-color: #ccc;
    margin: 0 20px;
  }
  
  .upload-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .header-section1 {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .add-plant-button {
      margin-top: 10px;
    }
  }
  