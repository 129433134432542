.hp-card {
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-height: 350px;
}

.hp-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  font-family: "LATO", "Open Sans";
  font-weight: bold;
  font-size: 18px;
}
.hp-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
}

.hp-title-container span {
  margin: 0;
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
}

.hp-information-icon {
  width: 20px;
  height: 20px;
  /*margin-left: 4px;*/
  cursor: pointer;
}

.hp-title-container img {
  margin: 0;

}

.hp-controls {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 0.9em;
}

.hp-custom-dropdown {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: 1px solid #818F96;
  border-radius: 5px;
}

.hp-custom-dropdown div {
  display: flex;
  align-items: center;
  
  padding: 3px;
  margin-right: 12px;
}

.hp-dropdownicon {
  margin-left: 8px;
  width: 16px;
  pointer-events: none;
}

.hp-divider {
  width: 0px;
  height: 24px;
  background-color: #e0e0e0;
  margin: 0 8px;
}

.hp-divider-below-title {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin: 8px 0;
}

.hp-chart-container {
  margin-top: 30px;
  flex-grow: 1;
  position: relative;
}

.hp-select {
  padding: 0;
}

.hp-dropdown-menu {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 8.8rem;
  margin-left: -1.5rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 150%;
}

.hp-dropdown-option {
  padding: 10px;
  cursor: pointer;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.hp-dropdown-option:hover,
.hp-dropdown-option-selected {
  background-color: #f0f0f0;
}

.hp-date-picker input {
  border: none; /* Removes any default border */
  outline: none; /* Removes any default outline */
  box-shadow: none; /* Removes any potential box shadow */
  padding: 4px 8px;
  text-align: center;
  font-size: 0.9rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.hp-date-picker {
  display: flex;
  align-items: center;
  border: 1px solid #ccc; /* Ensure only one border */
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
}

.hp-date-picker {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
}

.hp-calendar-icon {
  font-size: 1.2rem;
  color: #ccc;
  margin-left: 5px;
}

.hp-nav-button {
  background: none;
  border: none;
  cursor: pointer;
  color: hsl(180, 1%, 66%);
  font-size: 1.1rem;
  padding: 4px;
}

.hp-no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ccc;
}

.hp-no-data-icon {
  width: 50px;
  opacity: 0.6;
  margin-bottom: 10px;
}

.hp-no-data-container p {
  font-size: 0.9rem;
  color: #999;
}

.hp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.hp-modal.open {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.hp-modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  width: 90%;
  height: 80%;
  animation: zoomIn 0.3s ease-in-out;
}

.hp-modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.hp-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

.hp-modal-title {
  font-size: 24px;
  color: #333;
}

.hp-modal-card-content {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 60px); /* Adjusting for header height */
}
