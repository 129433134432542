.dashboard-container {
  display: flex;
  flex-direction: column;
}

.section2 {
  margin-top: -20px;
}

.section3 {
  margin-top: -60px;
}

.section4 {
  margin-top: -60px;
}
