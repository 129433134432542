.production-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(32% - 16px);
  min-width: 300px !important;
  width: 100%;
}

@media (max-width: 1024px) {
  .production-card {
    flex: 1 1 calc(48% - 16px);
  }
}

@media (max-width: 768px) {
  .production-card {
    flex: 1 1 100%;
    margin-bottom: 16px;
  }
}

.production-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -10px;
}

.production-title-container {
  display: flex;
  align-items: center;
}

.production-title {
  font-size: 20px;
  font-weight: 500;
  color: #0F0D0D;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .production-title {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .production-title {
    font-size: 12px;
  }
}

.production-divider {
  width: calc(100% + 40px);
  height: 1px;
  background-color: #ddd;
  position: relative;
  left: -20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .production-divider {
    margin-bottom: 15px;
  }
}

.production-barchart-wrapper {
  padding: 0px;
}

.production-energy-text {
  align-items: center;
  color: #167DB0;
  font-weight: bold;
  font-size: 16px;
  margin-left: auto;
}
.production-energy-text .energy-production{
  color:#1C2E38;
  font-size: 18px;
}

.production-left-text {
  font-size: 18px;
  color: #1C2E38;
  font-weight: 500;
  margin-right: 10px;
}
