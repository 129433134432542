.PA-performance-alerts-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  padding: 12px; /* Reduced padding */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 350px; /* Ensure card doesn't exceed this height */
  overflow: hidden; /* Prevent overflow */
}

.PA-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 15px;
}

.PA-header-left {
  display: flex;
  align-items: center;
}

.PA-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.PA-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .PA-icon img {
  width: 35px;
  cursor: pointer;
} */

.PA-close-icon {
  width: 35px;
  cursor: pointer;
}

.PA-card-title {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1C2E38;
  margin-left: 13px;
  margin-top: 10px;
}

.PA-info-icon {
  margin-left: 13px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.PA-setting-label {
  font-family: 'LATO', 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  color: #818F96;
}

.PA-setting-handle {
  width: 30px;
  /* height: 30px; */
}

.PA-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.PA-dropdown span {
  margin-right: 2px;
}

.PA-dropdown-arrow {
  width: 16px;
  height: 16px;
  margin-top: 5px;
}

.PA-calendar-icon {
  width: 26px;
  height: 26px;
}

.PA-expand-icon {
  width: 25px;
  height: 16px;
  cursor: pointer;
}

.PA-settings-dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: 0 4px 8px #efefef;
  padding: 12px; /* Slightly increase padding for better spacing */
  z-index: 1000;
  right: 70px; /* Align dropdown with settings icon */
  top: 190px; /* Position below the settings icon */
  width: 140px; /* Increase width to accommodate longer text */
}

.PA-dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px; /* Increase space between items */
  font-size: 12px; /* Match the font size as in the image */
  color: #333; /* Dark gray color for text */
  cursor: pointer;
}

.PA-dropdown-item input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 4px;
  margin-right: 12px; /* Space between checkbox and text */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  box-shadow: 0 2px 4px #efefef;
}

.PA-dropdown-item input[type="checkbox"]:checked {
  background-color: #ffffff;
  border-color: #90a4c8; /* Blue-gray border color */
}

.PA-dropdown-item input[type="checkbox"]:checked::before {
  content: "✔"; /* Unicode for checkmark */
  font-size: 16px; /* Adjust checkmark size */
  color: #90a4c8; /* Blue-gray color for checkmark */
}

.PA-dropdown-item:last-child {
  margin-bottom: 0;
}

.PA-divider {
  height: 15px;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  margin: 0 5px;
}

.PA-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden; /* Prevents overflow of content */
  padding: 30px 0px 0px;
}

.PA-pie-chart-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1; /* Allow pie chart to resize based on container */
  max-width: 50%; /* Ensure pie chart takes up a proper portion */
}

.PA-alerts-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  flex: 1;
  margin-left: 15px; /* Adjust margin to prevent overflow */
}

.PA-detail {
  margin-bottom: 22px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.PA-label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  min-width: 200px;
  color: #818F96;
  font-family:'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
}

.PA-value {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family:'Lato', sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
  min-width: 50px;
  text-align: right;
}

.PA-critical {
  color: #FF9500;
}

.PA-warnings {
  color: #FFD700;
}

.PA-normal {
  color: #88BFFF;
}

.recharts-responsive-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
