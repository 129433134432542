/* Add styles for dropdown menu */
.PO-dropdown-menu {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 260px;
  margin-left: -25px;
  width: 150px;
  z-index: 1000;
}

.PO-dropdown-option {
  padding: 10px;
  cursor: pointer;
  color: #333;
}

.PO-dropdown-option:hover,
.PO-dropdown-option-selected {
  background-color: #f0f0f0;
}

/* Add styles for date picker */
.PO-date-picker {
  position: absolute;
  margin-top: 350px;
  margin-left: -160px;
  z-index: 1000;
}

.custom-datepicker {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  width: 280px;
  min-height: 300px; /* Set a minimum height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-datepicker .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.custom-datepicker .react-datepicker__month-container {
  width: auto;
  flex: 1; /* Allow the month container to grow */
}

.custom-datepicker .react-datepicker__day {
  margin: 0.1px;
  width: 2.13rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  font-size: 0.875rem;
}

.custom-datepicker .react-datepicker__day--selected {
  background-color: #007bff;
  color: #ffffff;
}

.custom-datepicker .react-datepicker__day--today {
  font-weight: bold;
  color: hsl(0, 0%, 0%);
}

.custom-datepicker .react-datepicker__day-names,
.custom-datepicker .react-datepicker__week {
  display: flex;
  justify-content: center;
}

.custom-datepicker .react-datepicker__day-name {
  width: 1.82rem;
  height: 2rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
}

.custom-datepicker .react-datepicker__current-month {
  font-size: 1rem;
  font-weight: bold;
}

.custom-datepicker .react-datepicker__navigation--previous,
.custom-datepicker .react-datepicker__navigation--next {
  top: 10px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

/* Original CSS */
.PO-production-overview-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #888888;
  padding: 12px; /* Reduced padding */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 350px; /* Ensure card doesn't exceed this height */
  overflow: hidden; /* Prevent overflow */
  font-family: "Helvetica", "Arial", sans-serif;
}

.PO-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 15px;
}

.PO-header-left {
  display: flex;
  align-items: center;
}

.PO-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.PO-divider {
  height: 15px;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  margin: 0 5px;
}

.PO-card-title {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1C2E38;
  margin-left: 13px;
  margin-top: 10px;
}

.PO-info-icon {
  margin-left: 13px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.PO-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.PO-dropdown span {
  margin-right: 2px;
}

.PO-dropdown-arrow {
  width: 16px;
  height: 16px;
  margin-top: 5px;
}

.PO-calendar-icon {
  width: 26px;
  height: 26px;
}

.PO-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PO-expand-icon {
  width: 25px;
  height: 16px;
  cursor: pointer;
}

.PO-close-icon {
  width: 35px;
  
  cursor: pointer;
}

.PO-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden; /* Prevent overflow */
  flex-wrap: wrap; /* Allow wrapping if necessary */
  padding: 30px 20px;
}

.PO-gauge-container {
  /*width: 35%; /* Reduced width */
  max-width: 100%; /* Ensure it doesn't overflow */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 1; /* Allow shrinking */
}

.PO-percentage-box {
  margin-top: 5px;
  padding: 1px 5px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #167db0;
  font-size: 14px;
  font-weight: bolder;
}

.PO-center-section {
  width: 30%; /* Reduced width */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1; /* Allow shrinking */
}

.PO-right-section {
  width: 30%; /* Adjusted width */
  margin-right: 5px; /* Reduced margin */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1; /* Allow shrinking */
}

.PO-dotted-line-horizontal {
  width: 70%;
  border-top: 1px dashed #ccc;
  margin: 10px 0;
}

.PO-dotted-line-horizontal-small {
  width: 30%;
  border-top: 1px dashed #ccc;
  margin: 10px auto;
}

.PO-dotted-line-vertical-center {
  height: 100%;
  border-right: 1px dashed #ccc;
  margin: 0 10px;
}

.PO-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PO-detail-row-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.PO-detail-row-with-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PO-detail-row-with-divider .PO-detail-item:not(:last-child)::after {
  content: "";
  display: block;
  height: 100%;
  width: 1px;
  background-color: #ccc;
  margin: 0 10px;
}

.PO-detail-item-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.PO-detail-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.PO-label-left {
  font-family: "Lato", sans-serif;
  color: #888;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.PO-value-left {
  font-family: "Lato", sans-serif;
  color: #167db0;
  font-size: 18px;
  font-weight: bold;
}

.PO-label {
  font-family: "Lato", sans-serif;
  color: #888;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.PO-value {
  font-family: "Lato", sans-serif;
  color: #167db0;
  font-size: 18px;
  font-weight: bold;
}

.PO-value-units{
  font-family: "Lato", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.PO-title-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.PO-last-updated {
  font-size: 10px;
  color: #666; /* Adjust color as needed */
  margin-top: 4px; /* Slight margin to separate from title */
}


