.nav-link {
  padding-left: 30px;
  letter-spacing: 2px;
  color: #0f0d0d;
  font-size: 18px;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  color: #197fb1;
  border-bottom-width: 4px;
  border-bottom-color: currentcolor;
}

.nav-link:hover {
  color: #197fb1;
  border-bottom-width: 4px;
  border-bottom-color: currentcolor;
}

.overview-heading {
  padding-left: 20px;
  font-weight: bold;
  letter-spacing: 2px;
}

.entity-button {
  background-color: green !important;
}

.header-section {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  /* Added this line */
}

.header-section1 {
  display: flex;
  align-items: center;
  padding: 10px;
}

.header-section2 {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 15px;
}

.palnts {
  font-size: 24px;
  color: #333;
  padding: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.entity-count {
  font-size: 12px;
  color: #999;
  margin-left: 10px;
  margin-top: 9px;
}

.header-title {
  font-size: 20px;
  color: #333;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
}

/* Add New Entity Button */
.add-entity-button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.add-entity-button:hover {
  background-color: #444;
}

.divider {
  width: 1px;
  height: 20px;
  background-color: #ccc;
  margin: 0 20px;
}

.upload-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.checkbox-column .form-check-input:checked{
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path fill="%23black" d="M9 11.93l-3.47-3.47L4.1 10.88l5.9 5.9L20.48 6.29l-1.41-1.41L9 11.93z"/></svg>') no-repeat center;
                
}
.custom-model .modal-dialog{
  width: fit-content !important;
}

/* .mw-100 {
  padding-left: 20px;
} */

@media (max-width: 768px) {
  .header-section1 {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-entity-button {
    margin-top: 10px;
  }
}
